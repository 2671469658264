import Img from "gatsby-image"
import styled from "styled-components"

import headerDecoration from "../images/decoration-header.svg"
import paragraphDecoration from "../images/decoration-paragraph.svg"

import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import FormLabel from "@material-ui/core/FormLabel"
import MuiInput from "@material-ui/core/Input"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

export const Poster = styled(Img)`
  max-width: 488px;
`

export const PageHeader = styled(Typography)`
  background: transparent url(${headerDecoration}) no-repeat;
  background-size: 70px;
  background-position: top left;
  min-width: 70px;
  min-height: 70px;
  padding: 10px 0 0 0;
  span {
    font-weight: 400;
  }
`

export const BoxIntro = styled(Box)`
  ${({ theme }) => `
    min-height: 100%;
    max-width: 560px;
    ${theme.breakpoints.up("xs")} {
      padding: 0 20px 0 0;
      margin: 0 auto 40px;
    }
    ${theme.breakpoints.up("md")} {
      padding: 0 80px 0 0;
      margin: 0;
    }
  `}
`

export const BoxIntroDecor = styled(BoxIntro)`
  background: transparent url(${paragraphDecoration}) no-repeat;
  background-size: 6px 166px;
  background-position: 100% 90%;
`

export const BoxPoster = styled(Box)`
  ${({ theme }) => `
    padding: 0 19px 0 0;
    .gatsby-image-wrapper {
      position: relative;
      overflow: visible !important;
      margin: 0 auto;
      &:before {
        content: "";
        width: 60%;
        height: 14px;
        background-color: #ffffff;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
      &:after {
        content: "";
        width: 19px;
        height: 70%;
        background-color: #000000;
        position: absolute;
        bottom: -10%;
        right: -19px;
        z-index: 1;
      }
    }
    ${theme.breakpoints.up("md")} {
      padding: 0 0 0 20px;
      .gatsby-image-wrapper {
        margin: 0 0 0 auto;
      }
    }
  `}
`

export const BoxPosterInverse = styled(Box)`
  ${({ theme }) => `
    padding: 0 0 0 19px;
    .gatsby-image-wrapper {
      position: relative;
      overflow: visible !important;
      margin: 0 auto;
      &:before {
        content: "";
        width: 60%;
        height: 14px;
        background-color: #ffffff;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
      }
      &:after {
        content: "";
        width: 19px;
        height: 70%;
        background-color: #000000;
        position: absolute;
        bottom: -10%;
        left: -19px;
        z-index: 1;
      }
    }
    ${theme.breakpoints.up("md")} {
      padding: 0 20px 0 0;
      .gatsby-image-wrapper {
        margin: 0 auto 0 0;
      }
    }
  `}
`

export const SectionHeader = styled("p")`
  margin: 0 0 50px 0;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 3px;
  letter-spacing: 0.1ch;
  text-transform: uppercase;
  color: #000000;
  &:before {
    display: inline-block;
    content: "";
    width: 36px;
    height: 4px;
    margin: 9px 26px 0 0;
    vertical-align: top;
    background-color: #e0e0e0;
  }
`

export const Label = styled(FormLabel)``
export const Input = styled(MuiInput)``
export const Form = styled(Grid)`
  ${({ theme }) => `
   width: 100%;
    padding: 22px 30px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0;
    ${theme.breakpoints.up("md")} {
      padding: 52px 60px;
      margin: 0 0 0 20px;
    }
  `}
`

export const Submit = styled(Button)`
  ${({ theme }) => `
    margin-top: 24px;
    ${theme.breakpoints.up("sm")} {
      min-width: 220px;
    }
  `}
`
